import React, { useMemo, useState } from 'react'
import Markdown from 'markdown-to-jsx'
import Image from 'gatsby-image'
import tw from 'twin.macro'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import cn from 'classnames'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {
  BaseMDXProviderNew,
  MainNav,
  Overline,
  Container,
  H1,
  H2,
  SecondaryContent,
  H3,
  Caption,
  NewsLetterModuleFull,
  PrimaryContent,
  Seo,
  Tagline,
  Footer,
  // SectionHeader,
  PageHeader,
  Subtitle,
  InlinePhoto,
  QuoteImagePhoto,
  FullPhoto,
  QuotePhoto,
  PodcastBox,
  SubscribeBox,
  GlossaryTermLink,
  InlineVideo,
  SubsectionHeader,
  SubsectionIndented,
  Body1,
} from '../components'
import Logo from '../assets/aventine_logo_collapsed_white.svg'
import { SectionHeaderWithoutNav } from '../components/PageLayout/SectionHeader'
import { MailchimpSubscribeLanding } from '../components/MailchimpSubscribe'

const APPEND_CLASS_BASE = ` transition-opacity ease-in-out delay-1050 duration-1000 `

const SectionHeader = ({ subtitle, title }) => (
  <SectionHeaderWithoutNav subtitle={subtitle} title={title} />
)

export const components = {
  PageHeader,
  SectionHeader,
  Subtitle,
  InlinePhoto,
  QuoteImagePhoto,
  FullPhoto,
  QuotePhoto,
  PodcastBox,
  SubscribeBox,
  GlossaryTermLink,
  InlineVideo,
  SubsectionHeader,
  SubsectionIndented,
  inlineCode: ({ children, ...props }) => {
    return (
      <span
        className="text-faded italic"
        css={{ fontFamily: 'inherit', fontSize: 'inherit' }}
        {...props}
      >
        {children}
      </span>
    )
  },
  ul: props => (
    <PrimaryContent as={Body1}>
      <div>{props.children}</div>
    </PrimaryContent>
  ),
  li: props => <li className="mx-9 mb-6" {...props} />,
  blockquote: props => (
    <div css={{ display: 'flex' }}>
      <div tw="text-av-purple text-5xl mr-4" css={{ lineHeight: '1' }}>
        “
      </div>
      <div {...props} />
    </div>
  ),
}

export default function NewsletterLandingPage({
  data: {
    mdx: { frontmatter },
    newsletter,
    allFile,
  },
}) {
  const contentContainerRef = React.useRef()
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false)
  const [error, setError] = useState()
  const [appendClass, setAppendClass] = useState(APPEND_CLASS_BASE)
  const [refreshMessage, setRefreshMessage] = useState(false)
  const handleRefreshMessage = () => {
    setAppendClass(`opacity-0`)
    setRefreshMessage(prevState => !prevState)
  }

  const images = useMemo(
    () =>
      allFile.nodes
        .map(node => node?.childImageSharp?.fluid)
        .filter(src => !!src),
    []
  )
  const findIssue = issueNumber => {
    return newsletter.nodes.find(
      n => issueNumber === n.childMdx.frontmatter.issueNumber
    )
  }

  const findIssuePicAndHeader = issueNumber => {
    const issue = findIssue(issueNumber)
    const pic = images.find(
      i => i.originalName === issue?.childMdx?.frontmatter?.headerImage
    )
    const header =
      issue.childMdx.rawBody.match(
        /<SectionHeader[^>]*title="([^"]*)"[^>]*>/
      )?.[1] || ''
    const sectionId = header.replace(' ', '_')
    const sectionLink = `/${issue.childMdx.frontmatter.slug}#${sectionId}`
    return { pic, header, sectionLink }
  }
  const highlightsIssue = [
    findIssuePicAndHeader(frontmatter.highlightedIssue1),
    findIssuePicAndHeader(frontmatter.highlightedIssue2),
    findIssuePicAndHeader(frontmatter.highlightedIssue3),
  ]

  return (
    <>
      <MainNav />
      <div ref={contentContainerRef}></div>
      <div style={{ background: '#EEF5FF', textAlign: 'center', paddingRight: 20, paddingLeft: 20 }}>
        <div
          style={{
            maxWidth: '1300px',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 75,
            paddingBottom: 75,
          }}
        >
          <img
            src={Logo}
            alt="logo"
            className="mx-auto"
            style={{ maxWidth: '150px', marginBottom: 75 }}
          />
          <H3
            className="col-span-12 text-center mb-10 capitalize text-av-gray-6"
            style={{ fontSize: '16px' }}
          >
            {frontmatter.header}
          </H3>
          <H1 className="col-span-12 text-center mb-10 capitalize text-av-gray-6" style={{ fontSize: '48px' }}>
            {frontmatter.title}
          </H1>
          <H2 style={{ fontSize: '20px', fontWeight: 'light' }}>{frontmatter.description}</H2>

          <div style={{ height: 70 }}></div>

          <div
            style={{
              color: 'red',
              fontWeight: 'bold',
              marginTop: '20px',
              fontSize: '20px',
            }}
          >
            {error}
          </div>
          <div style={{ fontSize: '20px' }}>
            {subscriptionSuccess && (
              <div
                style={{
                  fontWeight: 'bold',
                  marginTop: '20px',
                  fontSize: '20px',
                }}
              >
                Thanks for subscribing! You will receive updates in your email
                when new projects are released.
              </div>
            )}
          </div>
          <MailchimpSubscribeLanding
            className=""
            onSuccess={() => {
              setSubscriptionSuccess(true)
              handleRefreshMessage()
            }}
            onError={errorMessage => {
              setError(errorMessage)
              handleRefreshMessage()
            }}
          />
          <div style={{ height: 80 }}></div>
          <H3
            style={{
              fontSize: 21,
              textTransform: 'uppercase',
              fontWeight: 700,
              marginBottom: 30,
            }}
          >
            Highlights from recent issues
          </H3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {highlightsIssue.map((issue, i) => (
              <div
                key={i}
                className="
                flex
                flex-col
                max-h-[500px]
                overflow-hidden
                border-2
                border-gray-400
                p-6
                bg-white
                justify-between
                rounded-lg
                "
              >
                <H2 style={{ fontSize: 28 }}>{issue.header}</H2>
                <Image
                  fluid={{ ...issue.pic, quality: 100 }}
                  alt="page art"
                  css={{
                    borderRadius: '7px',
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                />
                <button
                  onClick={() =>
                    (window.location.href = `${issue.sectionLink}`)
                  }
                  style={{
                    width: 'auto',
                    borderRadius: 7,
                    padding: 12,
                    backgroundColor: '#7048E8',
                    color: 'white',
                    alignSelf: 'flex-start',
                    fontSize: 16,
                    cursor: 'pointer',
                  }}
                >
                  Read the story
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query NewsletterLandingPageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        tagline
        header
        title
        description
        highlightedIssue1
        highlightedIssue2
        highlightedIssue3
      }
    }
    newsletter: allFile(filter: { relativeDirectory: { eq: "newsletter" } }) {
      nodes {
        name
        publicURL
        childMdx {
          frontmatter {
            issueNumber
            edition
            slug
            headerImage
          }
          rawBody
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "contents" } }) {
      nodes {
        name
        ext
        publicURL
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            originalName
            presentationWidth
            presentationHeight
          }
        }
        childVideoFfmpeg {
          mp4: transcode(
            maxWidth: 800
            maxHeight: 532
            fileExtension: "mp4"
            codec: "libx264"
          ) {
            width
            src
            presentationMaxWidth
            presentationMaxHeight
            originalName
            height
            fileExtension
            aspectRatio
          }
        }
      }
    }
  }
`
